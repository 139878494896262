
import { defineComponent, ref, nextTick } from 'vue';
import OverlayModal from '@/components/Calendar/OverlayModal.vue';
import swal from 'sweetalert2';
import _ from 'lodash';
import { useI18n } from 'vue-i18n';
import Q from 'q';

export default defineComponent({
    components: { OverlayModal },
    name: 'BusinessReceiptPaymentModal',
    async setup(props, { emit }: any) {
        const { t } = useI18n();
        const maxWidth = window.innerWidth;
        const isOpen = ref(false);
        const frameUrl = ref('');

        async function open(url: string) {
            swal.showLoading();
            frameUrl.value = url;
            await nextTick();
            await Q.delay(400);
            isOpen.value = true;
            swal.close();
        }
        function close() {
            isOpen.value = false;
        }
        window.onmessage = async (e: any) => {
            if (e.data['PELECARD_CATCH_GOOD']) {
                emit('addPaymentInReceiptFromIframe');
                close();
                return;
            }
            if (e.data['PELECARD_CATCH_ERROR']) {
                await swal.fire({
                    icon: 'error',
                    title: e.data['errorMessage']
                });
                close();
                return;
            }
            if (e.data['PELECARD_CATCH_CANCEL']) {
                close();
                await swal.fire({
                    icon: 'info',
                    title: t('pelecard.payment-canceled')
                });
                return;
            }
        };

        return {
            maxWidth,
            frameUrl,
            isOpen,
            open,
            close
        };
    }
});
