
import { defineComponent, ref, reactive, computed, PropType, toRef } from 'vue';
import OverlayModal from '@/components/Calendar/OverlayModal.vue';
import swal from 'sweetalert2';
import { api } from '@/services/Api';
import { InvoiceForReceipt } from '@/models/Accounting';
import _ from 'lodash';
import Q from 'q';
import { IdText } from '@/models/Interfaces';
import { generalStore } from '@/store';
import { BusinessReceiptInvoicesFilter } from '@/models/BusinessClients';
import { DateRange } from '@/models/Interfaces';
import useProduct from '@/modules/useProduct';
import Calendar from 'primevue/calendar';
import useOrder from '@/modules/useOrder';
import InputNumber from 'primevue/inputnumber';
import moment from 'moment';

export default defineComponent({
    components: {
        OverlayModal,
        Calendar,
        InputNumber
    },
    props: {
        clientId: {
            type: Number,
            required: true
        },
        invoices: {
            type: [] as PropType<InvoiceForReceipt[]>,
            default: []
        },
        totalAmount: {
            type: Number,
            default: 0
        },
        localCurrencySymbol: {
            type: String,
            default: ''
        },
        amountCoverByInvoces: {
            type: Number,
            default: 0
        }
    },
    name: 'BusinessReceiptInvoicesModal',
    async setup(props, { emit }: any) {
        const invoiceItems = toRef(props, 'invoices');
        const maxWidth = window.innerWidth;
        const selected = ref<string[]>([]);
        const filter = ref<BusinessReceiptInvoicesFilter>(new BusinessReceiptInvoicesFilter());
        const acceptedFilter = ref<BusinessReceiptInvoicesFilter>(new BusinessReceiptInvoicesFilter());
        const invoiceToView = computed(() => {
            let items: InvoiceForReceipt[] = JSON.parse(JSON.stringify(invoiceItems.value));
            if (acceptedFilter.value.invoiceNumber) {
                items = items.filter(x => x.documentNumber == acceptedFilter.value.invoiceNumber);
            }
            if (acceptedFilter.value.createDateStart) {
                const startDate = moment(acceptedFilter.value.createDateStart, 'YYYY/MM/DD');
                items = items.filter(x => moment(x.createdDate, 'DD/MM/YYYY') >= startDate);
            }
            if (acceptedFilter.value.createDateEnd) {
                const startDate = moment(acceptedFilter.value.createDateStart, 'YYYY/MM/DD');
                items = items.filter(x => moment(x.createdDate, 'DD/MM/YYYY') <= startDate);
            }
            return items;
        });
        const checkAll = ref(false);
        const isOpen = ref(false);
        const { dateRangeValidate } = useProduct();
        const dateRange = reactive(new DateRange());
        const { convertDate } = useOrder();

        const back = () => {
            isOpen.value = false;
        };
        async function save() {
            emit('addInvoices', selected.value);
            back();
        }

        async function search() {
            swal.showLoading();
            const filterObj: BusinessReceiptInvoicesFilter = JSON.parse(JSON.stringify(filter.value));
            if (!filterObj.invoiceNumber) {
                filterObj.invoiceNumber = null;
            }
            if (dateRange.fromDate) {
                filterObj.createDateStart = convertDate(dateRange.fromDate);
            }
            if (dateRange.toDate) {
                filterObj.createDateEnd = convertDate(dateRange.toDate);
            }
            acceptedFilter.value = filterObj;
            swal.close();
        }

        async function open() {
            swal.showLoading();
            await Q.delay(400);
            isOpen.value = true;
            swal.close();
        }

        function setSelectedItems(items: string[]) {
            selected.value = items;
        }

        function selectAll() {
            checkAll.value = !checkAll.value;
            if (checkAll.value) {
                selected.value = invoiceItems.value.map(x => x.baseAccountingDocumentId);
            } else {
                selected.value = [];
            }
        }

        function isChecked(id: string) {
            return selected.value.includes(id);
        }

        function onSelect(id: string) {
            const index = _.findIndex(selected.value, x => x == id);
            if (index > -1) {
                checkAll.value = false;
                selected.value.splice(index, 1);
            } else {
                selected.value.push(id);
            }
        }

        const dateChanged = () => {
            if (!dateRange.fromDate || !dateRange.toDate) {
                return;
            }
            dateRangeValidate(dateRange);
        };

        function clearFilter() {
            filter.value = new BusinessReceiptInvoicesFilter();
            dateRange.fromDate = null;
            dateRange.toDate = null;
        }

        function removeSelectedItem(itemId: string) {
            const index = selected.value.findIndex(x => x == itemId);
            if (index >= 0) {
                selected.value.splice(index, 1);
            }
        }

        return {
            back,
            invoiceToView,
            maxWidth,
            selectAll,
            isChecked,
            onSelect,
            isOpen,
            open,
            checkAll,
            save,
            selected,
            dateRange,
            dateChanged,
            clearFilter,
            search,
            removeSelectedItem,
            invoiceItems,
            filter,
            setSelectedItems
        };
    }
});
