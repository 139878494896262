
import { defineComponent, onMounted, ref, computed } from 'vue';
import { Receipt, InvoiceForReceipt } from '@/models/Accounting';
import swal from 'sweetalert2';
import { api } from '@/services/Api';
import q from 'q';
import _ from 'lodash';
import { generalStore } from '@/store';
import router from '@/router';
import { useI18n } from 'vue-i18n';
import InputNumber from 'primevue/inputnumber';
import DocumentHeader from '@/components/BusinessClients/DocumentHeader.vue';
import BusinessReceiptInvoicesModal from '@/components/BusinessClients/BusinessDocuments/BusinessReceiptInvoicesModal.vue';
import BusinessReceiptPaymentModal from '@/components/BusinessClients/BusinessDocuments/BusinessReceiptPaymentModal.vue';
import BusinessReceiptPelecardPaymentModal from '@/components/BusinessClients/BusinessDocuments/BusinessReceiptPelecardPaymentModal.vue';
import BusinessAccountingForTo from '@/components/BusinessClients/BusinessDocuments/BusinessAccountingForTo.vue';
import { ReceiptPaymentSteps } from '@/models/Enums';
import { AddPaymentInReceiptViewModel } from '@/models/Interfaces';
import {
    BusinessBankTransferPayment,
    BusinessCashPayment,
    BusinessCheckPayment,
    BusinessCreditCardPelecardPayment
} from '@/models/BusinessClients';
import { DocumentsFilter } from '@/models/Document';

export default defineComponent({
    props: {
        isNew: {
            type: Boolean,
            default: false
        },
        clientId: {
            type: Number
        },
        receiptId: {
            type: String,
            default: ''
        },
        editMode: {
            type: Boolean
        },
        orderId: {
            type: String,
            default: ''
        }
    },
    components: {
        BusinessReceiptInvoicesModal,
        InputNumber,
        BusinessReceiptPaymentModal,
        BusinessReceiptPelecardPaymentModal,
        DocumentHeader,
        BusinessAccountingForTo
    },
    async setup(props) {
        const { t } = useI18n();
        const loaded = ref(false);
        const showInvoices = ref(false);
        const receipt = ref(new Receipt());
        const businessInvoices = ref<InvoiceForReceipt[]>([]);
        const modalBusinessReceiptInvoices = ref<InstanceType<typeof BusinessReceiptInvoicesModal>>();
        const modalBusinessReceiptPayment = ref<InstanceType<typeof BusinessReceiptPaymentModal>>();
        const modalBusinessReceiptPelecardPayment = ref<InstanceType<typeof BusinessReceiptPelecardPaymentModal>>();
        const localCurrencyId = ref('');
        const localCurrencySymbol = ref('');
        const frameUrl = ref<string>('');
        const orderCurrencySymbol = ref('');
        const orderLeftToPay = ref(0);
        const orderCurrencyRate = ref(0);
        const orderCurrencyId = ref('');
        const tempAddPaymentInReceiptViewModel = ref();
        const isTerminalPaymentOnline = ref(false);
        const hasCancelExistingReceiptPermission = generalStore.getters.canCancelExistingReceipt;
        const paymentCreditCardExist = computed(() => receipt.value.creditCardPelecardPayments.length > 0);
        const paymentsExist = computed(
            () =>
                receipt.value.creditCardPelecardPayments.length ||
                receipt.value.cashPayments.length ||
                receipt.value.checkPayments.length ||
                receipt.value.bankTransferPayments.length
        );

        const receiptHeader = computed(() => {
            if (props.isNew) {
                return props.clientId ? t('header.new-business-receipt') : t('header.new-receipt');
            } else {
                return props.clientId
                    ? t('documentTypes.BusinessReceipt') + ' #' + (receipt.value.documentNumber ?? '')
                    : t('accounting.receipt') + ' #' + (receipt.value.documentNumber ?? '');
            }
        });

        const invoicesCount = computed(() => receipt.value.invoices?.length | 0);
        const invoicesAmount = computed(() =>
            receipt.value.invoices?.reduce((accumulator, object) => {
                return accumulator + object.amount;
            }, 0)
        );
        const invoicesAmountLeftToCover = computed(() =>
            receipt.value.invoices?.reduce((accumulator, object) => {
                return accumulator + object.leftToCoverInLocal;
            }, 0)
        );
        const invoicesAmountToCover = computed(() =>
            receipt.value.invoices.reduce((accumulator, object) => {
                return accumulator + object.amountToCover;
            }, 0)
        );

        const paymentsTotal = computed(() => {
            let initialValue = 0;
            initialValue = receipt.value.creditCardPelecardPayments?.reduce(
                (accumulator, currentValue) => accumulator + (currentValue.total || 0) * currentValue.currencyRate,
                initialValue
            );
            initialValue = receipt.value.cashPayments?.reduce(
                (accumulator, currentValue) => accumulator + (currentValue.total || 0) * currentValue.currencyRate,
                initialValue
            );
            initialValue = receipt.value.checkPayments?.reduce(
                (accumulator, currentValue) => accumulator + (currentValue.total || 0) * currentValue.currencyRate,
                initialValue
            );
            initialValue = receipt.value.bankTransferPayments?.reduce(
                (accumulator, currentValue) => accumulator + (currentValue.total || 0) * currentValue.currencyRate,
                initialValue
            );
            return initialValue;
        });

        const orderLeftToPayComputed = computed(() => {
            if (localCurrencyId.value == orderCurrencyId.value) {
                return orderLeftToPay.value - paymentsTotal.value;
            } else {
                return orderLeftToPay.value - paymentsTotal.value / orderCurrencyRate.value;
            }
        });

        const totalLeftToCoverInvoiceInLocal = computed(() =>
            receipt.value.invoices.reduce((accumulator, object) => {
                return accumulator + object.amountToCover;
            }, 0)
        );
        function addModalReceipts() {
            modalBusinessReceiptInvoices.value?.open();
        }
        function removeRow(index: number) {
            const idToRemove = receipt.value.invoices[index].baseAccountingDocumentId;
            modalBusinessReceiptInvoices.value?.removeSelectedItem(idToRemove);
            receipt.value.invoices.splice(index, 1);
        }
        function cancel() {
            if (props.clientId) {
                if (props.editMode) {
                    router.push({ name: 'businessReceiptView', params: { receiptId: props.receiptId, clientId: props.clientId } });
                } else {
                    router.push({ name: 'businessClientView', params: { page: 3, id: props.clientId } });
                }
            } else if (props.orderId) {
                if (props.editMode) {
                    router.push({ name: 'receiptView', params: { receiptId: props.receiptId, orderId: props.orderId } });
                } else {
                    router.push({ name: 'order', params: { id: props.orderId } });
                }
            }
        }
        const getCurrencySymbol = (id: string) => generalStore.getters.getCurrencySymbol(id);

        async function validateReceipt() {
            swal.showLoading();
            const toSaveReceipt: Receipt = JSON.parse(JSON.stringify(receipt.value));
            toSaveReceipt.invoicesTotalAmount = totalLeftToCoverInvoiceInLocal.value;
            const apiPromise = api.validateReceipt(toSaveReceipt);
            await q.delay(400);
            const apiResult = await apiPromise;
            if (apiResult.errorMessage) {
                swal.fire({
                    icon: 'error',
                    text: apiResult.errorMessage
                });
                return false;
            }
            if (apiResult.data?.isValid) {
                return true;
            }
            return false;
        }

        async function createReceipt() {
            swal.showLoading();
            const toSaveReceipt: Receipt = JSON.parse(JSON.stringify(receipt.value));
            toSaveReceipt.invoicesTotalAmount = totalLeftToCoverInvoiceInLocal.value;
            toSaveReceipt.timezone = -new Date().getTimezoneOffset();
            const apiPromise = api.createReceipt(toSaveReceipt);
            await q.delay(400);
            const apiResult = await apiPromise;
            if (apiResult.errorMessage) {
                swal.fire({
                    icon: 'error',
                    text: apiResult.errorMessage
                });
                return;
            }
            const documentId = apiResult.data?.id;
            if (documentId) {
                await swal.fire({
                    icon: 'success',
                    title: t('alert.receiptWasCreated')
                });
                if (props.clientId) {
                    router.push({ name: 'businessReceiptView', params: { clientId: props.clientId, receiptId: documentId } });
                }
                if (props.orderId) {
                    router.push({ name: 'order', params: { id: props.orderId } });
                }
            }
        }

        function ValidateForm() {
            if (props.clientId && totalLeftToCoverInvoiceInLocal.value > paymentsTotal.value) {
                return false;
            }
            return true;
        }

        function edit() {
            if (props.clientId) {
                router.push({ name: 'businessReceiptEdit', params: { clientId: props.clientId, receiptId: props.receiptId } });
            }
        }

        const submitForm = async (event: any) => {
            event.target.classList.add('was-validated');
            if (event.target.checkValidity() === false) {
                event.preventDefault();
                event.stopPropagation();
                swal.fire({
                    icon: 'info',
                    text: t('alert.pleaseFillAllRequiredFields')
                });
                return;
            } else if (!ValidateForm()) {
                event.preventDefault();
                event.stopPropagation();
                swal.fire({
                    icon: 'info',
                    text: 'Total invoices can’t be more than Total of payment'
                });
                return;
            }
            const isOk = await validateReceipt();
            if (!isOk) {
                return;
            }
            await createReceipt();
        };

        async function viewPdfDocument() {
            if (!receipt.value.pdfUrl) {
                swal.showLoading();
                const apiPromise = api.getBusinessReceiptPdf(props.receiptId);
                await q.delay(400);
                const apiResult = await apiPromise;
                if (apiResult.errorMessage) {
                    swal.fire({
                        icon: 'error',
                        text: apiResult.errorMessage
                    });
                    return;
                }
                if (!apiResult.data || apiResult.data.pdfUrl == null || apiResult.data.pdfUrlWithInvoices == null) {
                    swal.fire({
                        icon: 'error',
                        text: t('alert.pdfNotReady')
                    });
                    return;
                }
                receipt.value.pdfUrl = apiResult.data.pdfUrl;
                receipt.value.pdfUrlWithInvoices = apiResult.data.pdfUrlWithInvoices;
            }
            if (props.clientId) {
                const swalAction = await swal.fire({
                    icon: 'info',
                    text: t('alert.businessReceiptPdfMsg'),
                    customClass: {
                        confirmButton: 'popup-bookit-button my-1 px-4',
                        cancelButton: 'close-button-wrapper popup-bookit-button my-1'
                    },
                    buttonsStyling: false,
                    showCancelButton: true,
                    confirmButtonText: t('button.yes'),
                    cancelButtonText: t('button.no')
                });
                if (swalAction.isConfirmed) {
                    window.open(receipt.value.pdfUrlWithInvoices, '_blank');
                } else {
                    window.open(receipt.value.pdfUrl, '_blank');
                }
            } else {
                window.open(receipt.value.pdfUrl, '_blank');
            }
        }

        function addInvoices(selectedItems: string[]) {
            receipt.value.invoices = receipt.value.invoices.filter(x => selectedItems.includes(x.baseAccountingDocumentId));
            selectedItems.forEach(selectedItem => {
                if (!receipt.value.invoices.find(x => x.baseAccountingDocumentId == selectedItem)) {
                    const item = businessInvoices.value.find(x => x.baseAccountingDocumentId == selectedItem);
                    if (item) {
                        item.amountToCover = item.leftToCoverInLocal;
                        receipt.value.invoices.push(item);
                    }
                }
            });
            receipt.value.invoices = _.sortBy(receipt.value.invoices, [
                function(o) {
                    return o.documentNumber;
                }
            ]);
        }
        function removeCashPayment(index: number) {
            receipt.value.cashPayments.splice(index, 1);
        }
        function removeCheckPayment(index: number) {
            receipt.value.checkPayments.splice(index, 1);
        }
        function removeBankTransferPayment(index: number) {
            receipt.value.bankTransferPayments.splice(index, 1);
        }
        function editCashPayment(index: number) {
            const openReq: AddPaymentInReceiptViewModel = {
                selectedStep: ReceiptPaymentSteps.Cash,
                cashPayment: _.clone(receipt.value.cashPayments[index]),
                checkPayment: new BusinessCheckPayment(),
                bankTransferPayment: new BusinessBankTransferPayment(),
                creditCardPelecardPayment: new BusinessCreditCardPelecardPayment()
            };
            modalBusinessReceiptPayment.value?.open(openReq);
        }
        function editCheckPayment(index: number) {
            const toModalEntity = _.clone(receipt.value.checkPayments[index]);
            const openReq: AddPaymentInReceiptViewModel = {
                selectedStep: ReceiptPaymentSteps.Check,
                cashPayment: new BusinessCashPayment(),
                checkPayment: toModalEntity,
                bankTransferPayment: new BusinessBankTransferPayment(),
                creditCardPelecardPayment: new BusinessCreditCardPelecardPayment()
            };
            modalBusinessReceiptPayment.value?.open(openReq);
        }
        function editBankTransferPayment(index: number) {
            const toModalEntity = _.clone(receipt.value.bankTransferPayments[index]);
            const openReq: AddPaymentInReceiptViewModel = {
                selectedStep: ReceiptPaymentSteps.BankTransfer,
                cashPayment: new BusinessCashPayment(),
                checkPayment: new BusinessCheckPayment(),
                bankTransferPayment: toModalEntity,
                creditCardPelecardPayment: new BusinessCreditCardPelecardPayment()
            };
            modalBusinessReceiptPayment.value?.open(openReq);
        }

        async function shareDocument() {
            router.push({ name: 'sharedocument', params: { documentId: props.receiptId } });
        }

        function openPaymentModal() {
            modalBusinessReceiptPayment.value?.open();
        }
        function CreateUUID() {
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
                const r = (Math.random() * 16) | 0,
                    v = c == 'x' ? r : (r & 0x3) | 0x8;
                return v.toString(16);
            });
        }

        function addPaymentInReceipt(req: AddPaymentInReceiptViewModel) {
            if (req.selectedStep == ReceiptPaymentSteps.CreditCard) {
                if (req.cashPayment.fakeId) {
                    const findIndex = receipt.value.creditCardPelecardPayments.findIndex(x => x.fakeId == req.cashPayment.fakeId);
                    if (findIndex >= 0) {
                        receipt.value.creditCardPelecardPayments[findIndex] = _.clone(req.creditCardPelecardPayment);
                    }
                } else {
                    req.creditCardPelecardPayment.fakeId = CreateUUID();
                    receipt.value.creditCardPelecardPayments.push(req.creditCardPelecardPayment);
                }
            } else if (req.selectedStep == ReceiptPaymentSteps.Cash) {
                if (req.cashPayment.fakeId) {
                    const findIndex = receipt.value.cashPayments.findIndex(x => x.fakeId == req.cashPayment.fakeId);
                    if (findIndex >= 0) {
                        receipt.value.cashPayments[findIndex] = _.clone(req.cashPayment);
                    }
                } else {
                    req.cashPayment.fakeId = CreateUUID();
                    receipt.value.cashPayments.push(req.cashPayment);
                }
            } else if (req.selectedStep == ReceiptPaymentSteps.Check) {
                if (req.checkPayment.fakeId) {
                    const findIndex = receipt.value.checkPayments.findIndex(x => x.fakeId == req.checkPayment.fakeId);
                    if (findIndex >= 0) {
                        receipt.value.checkPayments[findIndex] = _.clone(req.checkPayment);
                    }
                } else {
                    req.checkPayment.fakeId = CreateUUID();
                    receipt.value.checkPayments.push(req.checkPayment);
                }
            } else if (req.selectedStep == ReceiptPaymentSteps.BankTransfer) {
                if (req.bankTransferPayment.fakeId) {
                    const findIndex = receipt.value.bankTransferPayments.findIndex(x => x.fakeId == req.bankTransferPayment.fakeId);
                    if (findIndex >= 0) {
                        receipt.value.bankTransferPayments[findIndex] = _.clone(req.bankTransferPayment);
                    }
                } else {
                    req.bankTransferPayment.fakeId = CreateUUID();
                    receipt.value.bankTransferPayments.push(req.bankTransferPayment);
                }
            }
        }

        async function clearCreditCard(req: BusinessCreditCardPelecardPayment) {
            swal.showLoading();
            const response = await api.PelecardInit(req.total ?? 0, req.currencyId, req.creditCardPaymentType, req.numberOfPayments);
            await q.delay(400);
            if (response.error) {
                swal.fire({
                    icon: 'error',
                    title: t('pelecard.error'),
                    text: response.errorMessage
                });
                return;
            }
            swal.close();
            if (!response.data) {
                return;
            }
            frameUrl.value = response.data.url;
            req.transactionId = response.data.transactionId ?? 0;
            const payment: AddPaymentInReceiptViewModel = {
                creditCardPelecardPayment: req,
                selectedStep: ReceiptPaymentSteps.CreditCard,
                cashPayment: new BusinessCashPayment(),
                checkPayment: new BusinessCheckPayment(),
                bankTransferPayment: new BusinessBankTransferPayment()
            };
            tempAddPaymentInReceiptViewModel.value = payment;
            modalBusinessReceiptPelecardPayment.value?.open(frameUrl.value);
        }

        function addPaymentInReceiptFromIframe() {
            modalBusinessReceiptPayment.value?.close();
            addPaymentInReceipt(tempAddPaymentInReceiptViewModel.value);
        }

        async function continueCreateReceipt() {
            await createReceipt();
        }

        function updateRecipientName(recipientName: string) {
            receipt.value.recipientName = recipientName;
        }
        function updateFor(forText: string) {
            receipt.value.for = forText;
        }

        async function onMountedAction() {
            swal.showLoading();
            if (props.isNew) {
                const apiPromise = api.getInfoForReceipt(props.clientId ?? 0, props.orderId);
                await q.delay(400);
                const apiResult = await apiPromise;
                if (apiResult.errorMessage) {
                    swal.fire({
                        icon: 'error',
                        text: apiResult.errorMessage
                    });
                    return;
                }
                if (!apiResult.data) {
                    swal.close();
                    return;
                }
                if (props.clientId) {
                    receipt.value.businessClientId = apiResult.data.businessClientId;
                    businessInvoices.value = apiResult.data.businessInvoices;
                } else {
                    receipt.value.orderId = props.orderId;
                    receipt.value.for = t('receipt.order-number') + apiResult.data.orderNumber.toString();
                }
                isTerminalPaymentOnline.value = apiResult.data.isTerminalPaymentOnline;
                receipt.value.recipientName = apiResult.data.recipientName;
                receipt.value.createdDate = apiResult.data.createdDate;
                localCurrencySymbol.value = apiResult.data.localCurrencySymbol;
                localCurrencyId.value = apiResult.data.localCurrencyId;
                orderLeftToPay.value = apiResult.data.leftToPay;
                orderCurrencyRate.value = apiResult.data.orderCurrencyRate;
                orderCurrencySymbol.value = apiResult.data.orderCurrencySymbol;
                orderCurrencyId.value = apiResult.data.orderCurrencyId;
            } else {
                const apiPromise = api.getBusinessReceipt(props.receiptId, props.editMode);
                await q.delay(400);
                const apiResult = await apiPromise;
                if (apiResult.errorMessage) {
                    swal.fire({
                        icon: 'error',
                        text: apiResult.errorMessage
                    });
                    return;
                }
                if (!apiResult.data) {
                    swal.close();
                    return;
                }
                receipt.value = apiResult.data.receipt;
                localCurrencySymbol.value = apiResult.data.localCurrencySymbol;
                businessInvoices.value = apiResult.data.businessInvoices;
                const ids = apiResult.data.receipt.invoices.map(x => x.baseAccountingDocumentId);
                modalBusinessReceiptInvoices.value?.setSelectedItems(ids);
            }
            loaded.value = true;
            swal.close();
        }

        async function cancelExistReceipt() {
            if (receipt.value.availableRefundAmount <= 0) {
                swal.fire({
                    icon: 'warning',
                    text: t('cancelReceipt.alreadyRefunded')
                });
                return;
            }
            swal.fire({
                text: t('title.amount-refund'),
                title: t('receipt.alert.enter-amount-refund'),
                icon: 'question',
                input: 'text',
                inputValue: receipt.value.availableRefundAmount,
                inputAttributes: {
                    autocapitalize: 'off'
                },
                customClass: {
                    confirmButton: 'save-button-wrapper popup-bookit-button my-1 px-5',
                    cancelButton: 'close-button-wrapper popup-bookit-button my-1 px-5'
                },
                showCancelButton: true,
                confirmButtonText: t('button.refund'),
                cancelButtonText: t('button.no'),
                showLoaderOnConfirm: true,
                allowOutsideClick: () => !swal.isLoading(),
                preConfirm: async total => {
                    if (isNaN(total)) {
                        swal.showValidationMessage(t('alert.only-numbers'));
                        return false;
                    }
                    if (Number(total) <= 0) {
                        swal.showValidationMessage(t('alert.only-positive-numbers'));
                        return false;
                    }

                    const responsePromise = api.CancelExistReceipt(props.receiptId, Number(total));
                    await q.delay(400);
                    const response = await responsePromise;
                    if (response.errorMessage) {
                        swal.showValidationMessage(response.errorMessage || 'error');
                        return false;
                    }
                    if (!response.data) {
                        return;
                    }
                    const filter = new DocumentsFilter();
                    filter.orderNumber = response.data.orderNumber;
                    filter.orderId = props.orderId;
                    generalStore.commit('saveDocumentsFilter', filter);
                    router.push({ name: 'documents' });
                    return true;
                }
            });
        }

        onMounted(onMountedAction);
        return {
            receipt,
            addModalReceipts,
            removeRow,
            loaded,
            cancel,
            submitForm,
            modalBusinessReceiptInvoices,
            modalBusinessReceiptPayment,
            modalBusinessReceiptPelecardPayment,
            businessInvoices,
            addInvoices,
            localCurrencySymbol,
            openPaymentModal,
            localCurrencyId,
            addPaymentInReceipt,
            removeCashPayment,
            removeCheckPayment,
            removeBankTransferPayment,
            editCashPayment,
            editCheckPayment,
            editBankTransferPayment,
            getCurrencySymbol,
            paymentsExist,
            paymentsTotal,
            frameUrl,
            continueCreateReceipt,
            invoicesCount,
            invoicesAmount,
            invoicesAmountLeftToCover,
            invoicesAmountToCover,
            edit,
            shareDocument,
            viewPdfDocument,
            updateFor,
            updateRecipientName,
            showInvoices,
            receiptHeader,
            orderLeftToPayComputed,
            orderCurrencySymbol,
            clearCreditCard,
            addPaymentInReceiptFromIframe,
            tempAddPaymentInReceiptViewModel,
            isTerminalPaymentOnline,
            paymentCreditCardExist,
            cancelExistReceipt,
            hasCancelExistingReceiptPermission
        };
    }
});
