
import { defineComponent, ref } from 'vue';
import Receipt from '@/components/Accounting/Receipt.vue';

export default defineComponent({
    props: {
        orderId: {
            type: String,
            required: true
        },
        receiptId: {
            type: String,
            required: true
        }
    },
    components: {
        Receipt
    },
    async setup() {
        return {};
    }
});
